<template>
<div class="my-4">
  <b-container>
    <b-row class="mb-4" v-if="!loading">
      <b-col>
        <b-card class="mb-3">
          <b-container>
            <b-row>
              <b-container>
                <b-dropdown id="tags-dropdown" variant="outline-primary" :text="tag.name" size="lg">
                  <b-dropdown-item v-for="item in tags" :key="'tags' + item.id">
                    <router-link :to="{ name: 'NewsTag', params: { id: item.id}}" class="dropdown-item p-1">{{item.name}}</router-link>
                  </b-dropdown-item>
                </b-dropdown>
                  <b-button class="ml-2" v-if="permissionEditTags" variant="outline-primary" v-on:click="showEdit">Edit description</b-button>
                </b-container>
              </b-row>
              <b-row v-if="!editTag.description && tag.description">
                <b-col>
                  <b-container class="ml-2 mt-2"><markdown :content="tag.description" /></b-container>
                </b-col>
              </b-row>
              <b-row v-if="editTag.description">
                <b-col>
                  <b-form-textarea rows="8" id="titleInput" v-model="tag.description" placeholder="Tag Description (markdown)" required class="mt-2 mb-2"></b-form-textarea>
                  <b-button class="mr-2" variant="primary" size="sm" @click="saveEdit" >Save</b-button>
                  <b-button variant="outline-primary" size="sm" v-on:click="cancelEdit">Cancel</b-button>
                </b-col>
              </b-row>
          </b-container>
        </b-card>
        <b-card class="mb-3" v-for="item in news" :key="item.id" :id="item.id">
          <publication
            v-bind:propItem="item"
            v-bind:key="item.id"
            :tagsProp=tags
            v-bind:username="user.username"
            :permissionAddArticle="permissionAddArticle"
            :permissionAddRisk="permissionAddRisk"
            :permissionAddTag="permissionAddTag"
            :permissionArticlesWip="permissionArticlesWip"
            :permissionInsights="permissionInsights"
            :permissionReadTagsPanel="permissionReadTagsPanel"
            />
        </b-card>
      </b-col>
    </b-row>
    <b-row v-show="loadingNews" class="text-center mb-4" align-h="center">
      <b-col cols="1">
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
    <b-row v-show="!loadingNews" class="text-center mb-4" align-h="center">
        <b-col>
            <b-button variant="primary" size="sm" v-if="showLoadMore" v-on:click="load">Load more</b-button>
        </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import ac from '../libs/accesscontrol'
import moment from 'moment'
import Publication from '@/components/PublicationNew'
import Markdown from '@/components/Markdown.vue'

export default {
  components: {
    Publication,
    Markdown
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.$stat.log({ page: 'tag', action: 'open tag', model: 'tag', model_id: this.$route.params.id })
    this.permissionAddArticle = ac.can(this.user.acgroups).createAny('article').granted
    this.permissionAddRisk = true
    this.permissionAddTag = ac.can(this.user.acgroups).createAny('newstag').granted
    this.permissionArticlesWip = ac.can(this.user.acgroups).createAny('articleWorkflowEdit').granted
    this.permissionReadAnalytics = ac.can(this.user.acgroups).readAny('analytics').granted
    this.permissionInsights = true
    this.permissionReadTagsPanel = ac.can(this.user.acgroups).readAny('tagspanel').granted
    this.permissionEditTags = ac.can(this.user.acgroups).updateAny('tag').granted
    if (this.$config.BUILD === 'external') {
      this.permissionAddRisk = false
      this.permissionAddTag = false
      this.permissionArticlesWip = false
      this.permissionDeletePublication = false
      this.permissionInsights = ac.can(this.user.acgroups).readAny('article').granted
      this.permissionReadAnalytics = false
      this.permissionReadTagsPanel = false
      this.permissionEditTags = false
    }
    this.date = moment()
    try {
      this.tags = await this.$Amplify.API.get('cosmos', '/tags')
    } catch (e) {
      this.$logger.warn('error getting events for a day', e)
    }
    this.firstLoad()
  },
  data () {
    return {
      loading: true,
      loadingNews: true,
      quarter: '',
      gridLoaderColor: 'black',
      gridLoaderSize: '10px',
      item: {},
      news: [],
      editTag: {
        description: false
      },
      permissionAddArticle: false,
      permissionAddRisk: false,
      permissionAddTag: false,
      permissionArticlesWip: false,
      permissionReadAnalytics: false,
      permissionReadTagsPanel: false,
      permissionEditTags: false,
      showLoadMore: true,
      tag: {},
      tagold: null,
      tags: [],
      limit: 50,
      offset: 0,
      page: 0
    }
  },
  methods: {
    firstLoad: async function () {
      this.showLoadMore = true
      this.$logger.debug('load started')
      this.loadingNews = true
      this.page = 0
      this.year = moment(this.date).year()
      this.quarter = moment(this.date).quarter()
      this.tag = this.tags.find(tag => parseInt(tag.id) === parseInt(this.$route.params.id))
      try {
        let apiName = 'cosmos'
        let path = `/news/tag/${this.$route.params.id}/limit/${this.limit}/offset/0`
        this.$logger.debug(path)
        let response = await this.$Amplify.API.get(apiName, path)
        if (response.length < this.limit) {
          this.showLoadMore = false
        }
        this.$logger.debug('load response: ', response)
        this.news = response
        this.$logger.debug(this.news)
        this.loading = false
        this.loadingNews = false
      } catch (e) {
        this.$logger.warn('load loading error' + e)
      }
    },
    load: async function () {
      this.$stat.log({ page: 'tag', action: 'open tag', model: 'tag', model_id: this.$route.params.id })
      this.$logger.debug('load started')
      this.loadingNews = true
      this.year = moment(this.date).year()
      this.quarter = moment(this.date).quarter()
      this.tag = this.tags.find(tag => parseInt(tag.id) === parseInt(this.$route.params.id))
      this.page = this.page + 1
      this.offset = this.page * this.limit
      try {
        let apiName = 'cosmos'
        let path = `/news/tag/${this.$route.params.id}/limit/${this.limit}/offset/${this.offset}`
        this.$logger.debug(path)
        let response = await this.$Amplify.API.get(apiName, path)
        if (response.length < this.limit) {
          this.showLoadMore = false
        }
        this.$logger.debug('load response: ', response)
        let news = response
        // push into existing array
        for (let i = 0, len = news.length; i < len; i++) {
          this.news.push(news[i])
        }
        this.$logger.debug(this.news)
        this.loading = false
        this.loadingNews = false
      } catch (e) {
        this.$logger.warn('load loading error' + e)
      }
    },
    cancelEdit: function () {
      this.tag = { ...this.tagold }
      this.editTag.description = false
    },
    showEdit: function () {
      this.tagold = { ...this.tag }
      this.editTag.description = true
    },
    saveEdit: async function (property) {
      this.$logger.debug(`saving tag start`)
      this.saving = true
      try {
        let apiName = 'cosmos'
        let path = `/standard/tag/${this.tag.id}`
        let params = {
          body: this.tag
        }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        this.editTag.description = false
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.saving = false
    }
  },
  watch: {
    $route: async function () {
      this.loading = true
      this.$stat.log({ page: 'tag', action: 'open tag', model: 'tag', model_id: this.$route.params.id })
      await this.firstLoad()
    }
  }
}
</script>

<style>
</style>
